<template>
    <div>
        <NavigationDrawer/>
        <transition name="fade" mode="out-in">
            <router-view class="container-fluid main-content scrollbar px-4 pt-4"/>
        </transition>
    </div>
</template>

<script>
import NavigationDrawer from '@/components/navigation/NavigationDrawer';
import store from '@/store/store';

async function doRouting(to, next) {
  if (!await store.dispatch('auth/loggedInGuard', to)) {
    return;
  }
  // On initial startup load product data and add productId if not set in url.
  if ((store.state.products.productId !== to.params.productId) || !to.params.productId) {
    // await store.dispatch('profile/init');
    await store.dispatch('user/getUser');
    to.params.productId = await store.dispatch('products/init', to.params.productId);
    if (!to.params.productId) {
      return next({ name: 'products' });
    }

    return next(to);
  }
  return next();
}

export default {
  components: {
    NavigationDrawer,
  },
  beforeRouteEnter: (to, from, next) => doRouting(to, next),
  beforeRouteUpdate: (to, from, next) => doRouting(to, next),
};
</script>
