<template>
    <router-link :to="toProps">
        <slot></slot>
    </router-link>
</template>

<script>

export default {
  name: 'DashboardLink',
  props: {
    to: {},
  },
  computed: {
    toProps() {
      const to = { ...this.to };
      if (!to.hasOwnProperty('params')) {
        to.params = {};
      }

      if (!to.params.hasOwnProperty('productId')) {
        to.params.productId = this.$store.state.products.productId;
      }

      return to;
    },
  },
};
</script>
