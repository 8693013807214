<template>
    <li class="navigation-drawer__item">
        <DashboardLink :to="to">
            <span>
                <span class="material-symbols-rounded navigation-drawer__icon"
                      v-if="icon">
                    {{ icon }}
                </span>
                <span class="navigation-drawer__text">{{ text }}</span>
            </span>
            <span class="navigation-drawer__badge" v-if="badgeText">
                {{ badgeText }}
            </span>
        </DashboardLink>
    </li>
</template>

<script>
import DashboardLink from '@/components/navigation/DashboardLink';

export default {
  components: { DashboardLink },
  props: ['to', 'icon', 'text', 'badgeText'],
};
</script>
