<template>
    <nav class="navigation-drawer scrollbar"
         :class="{'navigation-drawer--collapsed': isCollapsed, 'navigation-drawer--open': isOpen}">
        <div>
            <ul class="navigation-drawer__list mb-1">
                <li class="ms-3 d-flex justify-content-between align-items-start">
                    <div v-if="!isCollapsed">
                        <p class="h6 mb-0">
                            ProTrader<br>
                        </p>
                        <button class="btn btn-sm text-secondary px-0" type="button"
                                v-b-toggle.products-select>
                            <small>{{ product ? product.name : '' }}</small>
                            <span class="material-symbols-rounded navigation-drawer__icon me-0">
                                arrow_drop_down
                            </span>
                        </button>
                        <b-collapse id="products-select">
                            <button class="btn btn-sm px-0"
                                    :class="{ 'text-secondary': !isProduct(product) }"
                                    v-for="product in products"
                                    :key="product.id"
                                    @click="selectProduct(product)"
                            >
                                <small>
                                    {{ product.name || 'Unnamed product' }}
                                    <span class="material-symbols-rounded navigation-drawer__icon me-0 size-20"
                                          v-if="isProduct(product)">
                                        check
                                    </span>
                                </small>
                            </button>
                        </b-collapse>
                    </div>
                    <button class="btn navigation-drawer__toggle px-3" type="button" @click="toggle">
                        <span class="material-symbols-rounded navigation-drawer__icon me-0">
                            {{ isCollapsed ? 'menu' : 'menu_open' }}
                        </span>
                    </button>
                </li>
            </ul>
            <ul class="navigation-drawer__list">
                <li class="mb-4">
                    <DashboardLink :to="{ name: 'alert', params: { section: 'drafts', id: 'new' } }"
                                   class="btn btn-dark btn-fab">
                        <span class="material-symbols-rounded navigation-drawer__icon">edit</span>
                        <span v-if="!isCollapsed">Create alert</span>
                    </DashboardLink>
                </li>
                <NavigationItem :to="{ name: 'alerts', params: { section: 'drafts' } }"
                                icon="pending_actions"
                                text="Drafts"
                                :badge-text="alertDraftsCount"/>
                <NavigationItem :to="{ name: 'alerts', params: { section: 'sent' } }" icon="outbox" text="Sent"/>
                <NavigationItem :to="{ name: 'alerts', params: { section: 'discarded' } }" icon="archive"
                                text="Discarded"/>
            </ul>
            <ul class="navigation-drawer__list" v-if="!isCollapsed">
                <li class="navigation-drawer__headline">Positions</li>
                <NavigationItem :to="{ name: 'positions', params: { section: 'open' } }"
                                icon="list_alt"
                                text="Open positions"/>
                <NavigationItem :to="{ name: 'positions', params: { section: 'closed' } }"
                                icon="done_all"
                                text="Closed positions"/>
            </ul>
        </div>
        <ul class="navigation-drawer__list" v-if="!isCollapsed">
            <li class="navigation-drawer__headline">General</li>
            <NavigationItem :to="{ name: 'settings' }" icon="settings" text="Product settings"/>
            <NavigationItem :to="{ name: 'subscribers' }" icon="group" text="Subscribers"/>
            <NavigationItem :to="{ name: 'pages' }" icon="article" text="Pages"/>
            <NavigationItem :to="{ name: 'profile' }" icon="account_circle" text="Profile"/>
        </ul>
    </nav>
</template>

<script>
import NavigationItem from '@/components/navigation/NavigationItem';
import { mapGetters, mapState } from 'vuex';
import { VBToggle, BCollapse } from 'bootstrap-vue';
import DashboardLink from '@/components/navigation/DashboardLink';

export default {
  components: {
    DashboardLink,
    NavigationItem,
    BCollapse,
  },
  mounted() {
    this.initNavigationDrawer();
  },
  directives: {
    'b-toggle': VBToggle,
  },
  computed: {
    ...mapGetters({
      products: 'products/all',
      product: 'products/product',
      alertDraftsCount: 'alerts/alertDraftsCount',
    }),
    ...mapState({
      isCollapsed: (state) => state.navigationDrawer.isCollapsed,
      isOpen: (state) => state.navigationDrawer.isOpen,
    }),
  },
  methods: {
    isProduct(product) {
      return this.product && product.id === this.product.id;
    },
    selectProduct(product) {
      this.$root.$emit('bv::toggle::collapse', 'products-select');
      this.$router.push({ params: { productId: product.id } });
    },
    initNavigationDrawer() {
      const isCollapsed = localStorage.getItem('isNavigationDrawerCollapsed');
      if (isCollapsed !== null) {
        this.$store.commit('navigationDrawer/isCollapsed', JSON.parse(isCollapsed));
      }
    },
    toggle() {
      localStorage.setItem('isNavigationDrawerCollapsed', !this.isCollapsed);
      this.$store.commit('navigationDrawer/isCollapsed', !this.isCollapsed);
    },
  },
};
</script>
